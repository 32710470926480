// Custom colors
$custom-colors: (
  "tertiary": $tertiary
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Define custom font sizes
$display-font-sizes: (
  1: 3rem,
  2: 2.5rem,
  3: 2rem,
  4: 1.5rem,
  5: 1rem,
  6: 0.5rem,
);

// $body-secondary-color: rgba($dark, .6);

// Define custom breadcrumb divider
$breadcrumb-divider: url(
  "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color}'/></svg>"
);

// Define custom navbar styles
$dropdown-border-color:             rgba($white, .15);
$dropdown-item-padding-y:           .45rem;
$link-hover-color:                  $primary;
$link-hover-decoration:             none;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            transparent;
$nav-tabs-link-active-color:        $gray-700;
$navbar-light-color:                $gray-600;
$navbar-light-hover-color:          $primary;
$navbar-nav-link-padding-x:         .25rem;
$nav-pills-border-radius:           50rem;
