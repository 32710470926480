// Custom colors
$primary:   #00b265;
$secondary: #5d5d5d;
$tertiary:  #0074d9;
// $success:    $teal;
// $info:       $blue;
// $warning:    $yellow;
$danger:    #ff4136;
$light:     #F3F3F3;
$dark:      #343a40;
