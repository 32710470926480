/* Theme specific functions
-------------------------------------------------- */

@function wave-horizontal-svg($fill-color) {
  // remove the # from the color string
  $fill-color: str-slice(inspect($fill-color), 2);
  @return "data:image/svg+xml,\
  <svg width='140' height='21' viewBox='0 0 140 19' fill='none' xmlns='http://www.w3.org/2000/svg'>\
    <path fill-rule='evenodd' clip-rule='evenodd' d='M0 20.0012V0.0011996L0.0705672 0C15.8702 0.0411473 23.9974 4.30331 32.2182 8.98231L35.0058 10.5711C43.3111 15.2759 52.3664 19.8297 69.2725 19.8297C86.4082 19.8297 95.4776 15.1512 103.877 10.3787L106.673 8.78467C114.696 4.22876 122.735 0.122184 138.013 0.0023875L138.933 0C139.293 0.000937838 139.649 0.00406843 140.001 0.00934674L140 20.0012H0Z' fill='%23#{$fill-color}' />\
  </svg>";
}

@function wave-vertical-svg($fill-color) {
  // remove the # from the color string
  $fill-color: str-slice(inspect($fill-color), 2);
  @return "data:image/svg+xml,\
  <svg width='21' height='140' viewBox='0 0 21 140' fill='none' xmlns='http://www.w3.org/2000/svg'>\
    <path fill-rule='evenodd' clip-rule='evenodd' d='M-1.90735e-06 -0.000976801L20 -0.000976563L20.0012 0.0695907C19.9601 15.8692 15.6979 23.9964 11.0189 32.2173L9.43006 35.0048C4.72532 43.3101 0.171497 52.3655 0.171497 69.2715C0.171496 86.4073 4.85004 95.4766 9.62249 103.876L11.2165 106.672C15.7724 114.695 19.879 122.734 19.9988 138.012L20.0012 138.932C20.0003 139.292 19.9971 139.648 19.9918 140L-3.57683e-06 139.999L-1.90735e-06 -0.000976801Z' fill='%23#{$fill-color}' />\
  </svg>";
}
