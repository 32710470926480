/* Theme specific SCSS
-------------------------------------------------- */
@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('/fonts/NotoSansJP-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

// Define body font styling for different languages
body {
  font-family: 'Noto Sans',
    system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body:lang(ja-jp) {
  --bs-body-font-size: 1.1rem;
  --bs-body-line-height: 1.7;
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'Yu Gothic', Meiryo, sans-serif;
}

header {
  .navbar-brand img {
    height: 60px;
  }

  .navbar-brand svg {
    fill: $primary;
    height: 50px;
  }

  .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
  }

  .navbar-expand-lg .navbar-i18n .nav-link {
    padding-right: .5rem;
  }

  .dropdown-toggle.dropdown-chevron::after {
    vertical-align: 0;
    margin: 0;
    content: "\2304";
    border: none;
    left: 0.15em;
    bottom: 0.25em;
    position: relative;
  }
}

main {
  min-height: 350px;
  margin-top: 90px;

  .banner-wave {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 200px;

    + div,
    + article {
      background-image: url(wave-horizontal-svg($white));
      background-repeat: repeat-x;
      background-position: top;
      margin-top: -20px;
      padding-top: 60px !important;
    }
  }

  .bg-block-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;

    .bg-block-wave {
      background-image: url(wave-horizontal-svg($gray-100));
      background-repeat: repeat-x;
      background-position: bottom;
      height: 350px;
    }

    @each $breakpoint, $min-width in $grid-breakpoints {
      @if $breakpoint == "lg" or $breakpoint == "xl" {
        @media (min-width: $min-width) {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto 100%;

          .bg-block-wave {
            background-image: url(wave-vertical-svg($gray-100));
            background-repeat: repeat-y;
            background-position: left;
            min-height: 550px;
          }
        }
      }
    }
  }
}

article {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.8em;
  }

  pre {
    padding: 0.8rem;
    margin: 1.5rem auto;
    background-color: $gray-100;
  }
}

footer {
  padding-top: 40px;
  $bg_color_footer: $secondary;

  .logo svg {
    fill: var(--bs-body-bg);
    height: 40px;
  }

  .banner-wave {
    background-image: url(wave-horizontal-svg($bg_color_footer));
    background-repeat: repeat-x;
    background-position: top;
    padding-top: 20px;
    margin-top: -20px;
  }

  .font-slogan {
    font-size: 10px;
  }

  .bg-footer-top > div {
    background-color: $bg_color_footer;
  }

  .bg-footer-bottom {
    background-color: $primary;
  }
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f3f3f3;
}

// Styles for icons and their larger versions
.feather {
  width: 16px;
  height: 16px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather-large {
  width: 64px;
  height: 64px;
}

.feather-center, .svg-center {
  display: inline-flex;
  align-self: center;
}

// Styling for inline list separators
.list-inline-item.separator + .list-inline-item.separator:before {
  content: " | "
}

.list-inline-item.separator:not(:last-child) {
  margin-right: 0;
}

// Styles for animated cards
.card.card-animated {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}

.card.card-animated:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(97, 96, 96, 0.1), 0 4px 8px rgba(0,0,0,.06);
}

// Styles for text clamping
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Style for tables
table.table {
  caption-side: top;
}

// // Style for info text
// .text-info {
//   --bs-text-opacity: 1;
//   color: $teal !important;
// }

// Styling for tag wrapper
#tag-wrapper > span {
  cursor: pointer;
}
